.search-screen-header-item {
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202.5px;
    height: 56px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    background: linear-gradient(125.66deg, rgba(72, 86, 83, 0.4266) 3.23%, rgba(93, 95, 82, 0.54) 55.47%, rgba(68, 93, 87, 0.54) 97.32%);
}