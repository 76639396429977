$input-padding: 10px;
$border-color: #ccc;
$black-color: #000;

.container {
  padding: 12px;
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  background-color: white; // Page background color
  @media (max-width: 768px) {
    padding: 8px;
    max-width: 600px;
  }
}

.title {
  text-align: center;
  font-size: 19px;
  margin-bottom: 20px;
  padding: 15px;
  background-image: url("../../../assets/ppeRequirements//Header.png"); // Add your image path here
  background-size: cover;
  background-position: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.attentionSection {
  border: 2px solid $black-color; // Black border for the section
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
}

.attention {
  background-color: yellow;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 10px 42px;
}

.subHeader {
  font-size: 14px;
  font-weight: bold;
  color: red;
}

.paragraph {
  font-size: 16px;
  margin-bottom: 10px;
}

.imageSection {
  text-align: center;
  margin-bottom: 20px;

  img {
    max-width: 100%; // Ensures the image does not exceed the container width
    height: auto; // Maintains the aspect ratio
    border-radius: 5px; // Optional: Adds rounded corners
  }
}

.note {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.disclaimer {
  font-size: 16px;
  margin-bottom: 20px;
}

.acknowledgement {
  padding-top: 10px;
}

.signatureSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.label {
  font-size: 16px;
  margin-bottom: 10px;
}

.signatureLabel {
  font-size: 16px;
  margin-top: 10px;
}

.input {
  width: 100%;
  padding: $input-padding;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid $border-color;
}
