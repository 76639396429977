.SafeOperatingProcedure {
  .SafeOperatingProcedurePdfPage {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat, repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px 50px;
    border-radius: 8px;
    margin-bottom: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

    .safeOpProCol {
      border: 1px solid black;
      padding: 1rem;
    }

    .safeOpProCol-tableHeader {
      border: 1px solid black;
      padding: 1rem;
      text-align: center;
    }

    .sop-tableData {
      min-height: 110px;
    }

    .col-2,
    .col-sm-2,
    .safeOpProCol-tableHeader {
      font-weight: 600;
    }

    .safeProcedure-req {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    @media screen and (max-width: 768px) {
      padding: 10px 18px;
    }
  }
}
