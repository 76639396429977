.custom-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.76); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Adjust the z-index as needed */
  }
  
  .popup-content {
    padding: 34px 50px;
    border-radius: 12.5px;
    border: 1px solid white;
    background-color: rgba(24, 33, 37, 0.79);
    display: flex;
    flex-direction: column;
    align-items: center;
  } 
  
  .close-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
  }
  