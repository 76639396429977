.inbound-container {
  background: white;
  padding: 2rem;
  .header {
    font-weight: 700;
    text-decoration: underline;
    margin-bottom: 1rem;
  }

  .inbound-img {
    img {
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 300px;
      }
    }
  }
}
