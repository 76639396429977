/* ProfileContainer.css */
.profile-container {
    display: flex;
    align-items: center;
    padding: 12px;
}

.profile-photo {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 26px;
    margin-left: 11px;
}

.profile-info {
    flex: 1;
}
  