.admin-heading {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 44px;
}

.heading-text {
    margin: 0;
    margin-bottom: 44px;
    font-size: 32px;
    font-weight: 700;
    color: white;
    text-shadow: 3px 4px 7px rgba(0, 43, 85, 1);
}

.text-danger {
    color: red;
    font-size: 20px;
}

.buttons-dashboard {
    display: flex;
    align-items: center;
}
.btn {
    margin-left: 7px;
}

/* Main content */
.main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    border-radius: 12.5px;
    border: 1px solid white;
    background-color: rgba(24, 33, 37, 0.79);
    padding-left: 29px;
    padding-top: 4.5px;
    padding-bottom: 4.5px;
}

.main-container-cover {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    margin: 8px;
}

.main-container-scrollable {
    max-height: 450px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4a4a4a #272727;
}

.main-container-scrollable::-webkit-scrollbar {
    width: 8px;
}

.main-container-scrollable::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 6px;
}

.card-in-main-container {
    margin-top: 30.5px;
    margin-right: 47px;
}

.induction-learning-delete-training {
    display: flex;
    color: red;
    cursor: pointer;
    align-items: center;
    width: 143px;
    justify-content: center;
    margin-bottom: 9.5px;
}

.btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.red-text {
    color: red;
}