.link-tag-style {
   cursor: pointer;
   color: white;
}

.selected-user {
   height: 2px;
   background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(155, 158, 227, 1), rgba(0, 0, 0, 0));
}

.selected-user-color {
   color: rgba(106, 190, 251, 1);
   cursor: pointer;
}

.user-list-search-component {
   max-width: 246px;
   max-height: 550px;
   overflow-y: auto;
   scrollbar-width: thin;
   scrollbar-color: #4a4a4a #272727;
}

.user-list-search-component::-webkit-scrollbar {
   width: 8px;
}

.user-list-search-component::-webkit-scrollbar-thumb {
   background-color: #4a4a4a;
   border-radius: 6px;
}