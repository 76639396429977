.scrollable-container-child {
   max-height: 475px;
   padding-right: 15px;
   overflow-y: auto;
   scrollbar-width: thin;
   scrollbar-color: #4a4a4a #272727;
   border: none;
}

.scrollable-container {
   max-height: 500px;
   max-width: 65vw;
   width: 65vw;
   font-size: 16px;
   border: 1px solid white;
   background-color: rgba(196, 196, 196, 1);
   border-radius: 15px;
   padding: 12.5px;
}

.scrollable-container-child::-webkit-scrollbar {
   width: 12px;
}

.scrollable-container-child::-webkit-scrollbar-thumb {
   background-color: #4a4a4a;
   border-radius: 6px;
}

.option-right {
   color: green;
}

.option-wrong {
   color: red;
}