.scrollable-container-parent-edit-mcq {
   width: 65vw;
   max-height: 500px;
   height: auto;
   font-size: 16px;
   border: 1px solid white;
   background-color: rgba(196, 196, 196, 1);
   border-radius: 15px;
   padding: 12.5px;
}

.scrollable-container-edit-mcq {
   max-height: 475px;
   /* max-width: 65vw - 50px; */
   overflow-y: auto;
   scrollbar-width: thin;
   scrollbar-color: #4a4a4a #272727;
   /* Change these colors as needed */
}

.scrollable-container-edit-mcq::-webkit-scrollbar {
   width: 12px;
}

.scrollable-container-edit-mcq::-webkit-scrollbar-thumb {
   background-color: #4a4a4a;
   border-radius: 6px;
}

.options {
   display: flex;
   width: 100%;
   justify-content: space-between;
   padding-right: 15px;
}

.options label {
   padding: 0;
   margin: 0;
   width: max-content;
}

.options input {
   padding: 0;
   margin: 0;
   min-width: 50px;
}