.main-screen {
    width: 100%;
    height: 733px;
    padding-left: 67px;
    padding-right: 67px;
    padding-top: 48px;
    border: 1px solid white;
    border-radius: 25px;
    background-color: rgba(24, 33, 37, 0.79);
    box-shadow: 2px 6px 4px 0px rgba(255, 255, 255, 0.56);
}