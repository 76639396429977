
.create_induction_user {
  display:flex;
  flex-direction: column;
  flex-grow: 1;
  .create_induction_user_header{
  background: rgb(13, 8, 85);
  background: linear-gradient(
    90deg,
    rgb(13, 8, 85) 0%,
    rgb(53, 76, 193) 50%,
    rgb(13, 8, 85) 100%
  );
  height: 130px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  }
  .create_induction_user_bg {
    background: rgb(199, 199, 201);

    background: linear-gradient(
      180deg,
      rgba(199, 199, 201, 0.30996148459383754) 5%,
      rgba(0, 0, 40, 0.39119397759103647) 92%
    );
    min-height: 590px;
    flex-grow: 1;
    height: 80%;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
