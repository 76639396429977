.navbar--Header {
  background-image: url("../../images/navbarHeader.png"),
    linear-gradient(90.02deg, #1b2237 9.71%, rgba(27, 34, 55, 0.97) 96.99%);
  background-size: cover;
}
.navbar--container {
  max-width: 100% !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

a.nav-link.active {
  // color: #091664 !important;
  font-weight: 600;
}

.navbar-nav {
  a.nav-link:hover {
    cursor: pointer;
    color: rgb(39, 82, 190) !important;
    // background-color: #4856a2 !important;
    border-radius: 10px;
  }
}
.logout_button:hover {
  border: none !important;
}
.dropdown-toggle {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.myprofile--modal{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(49, 82, 114, 0.427);
  border-radius: 10px;
  .myprofile--picture{
    width:150px;
    height: 150px;
  }
}

.avatarComp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .avatar-img {
    width: 50;
    height: 50;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  .text-container {
    color: white;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    overflow: hidden;
    .name {
      text-transform: capitalize;
    }
    .role {
      // text-white mx-2 fw-bold
      font-weight: bold;
    }
  }
}
.dropdown-toggle{
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.profile-avatar {
  width: 50;
  height: 50;
  object-fit: cover;
  border-radius: 50%;
}