.updateStatusModal-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .updateStatusModal-form {
    width: 100%;
    background-image: url("../../../../public/backgrounds/triangle-shade.png");
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat, repeat;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px 50px;
    border-radius: 8px;
    color: rgba(17, 50, 80, 1);
    font-weight: 600;

    .credit-form-btn {
        background-color: rgba(65, 145, 64, 1);
        color: white;
        border-radius: 4px;
        border: none;
        padding: 7px 0;
        width: 30%;
      }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-right: 1rem;
      text-align: start;
    }
  }
}
