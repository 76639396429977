.search-bar {
    text-decoration: none;
    box-shadow: none;
    border: none;
    padding-left: 8px;
    padding-right: 34px;
    width: 202px;
    max-width: 244px;
    height: 46px;
    font-size: 14px;
    border-radius: 12.5px;
}
.search-bar:focus {
    outline: none;
}

.search-bar-input-container {
    display: flex;
    position: relative;
    padding: 24px 0;
}

.search-icon {
    position: absolute;
    right: 8px; /* Adjust for desired spacing */
    top: 50%;
    transform: translateY(-50%);
}