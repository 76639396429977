.add-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 45px;
}

.view-btn {
    display: flex;
    align-items: center;
    margin-right: 57px;
    cursor: pointer;
}

.view-btn-text {
    margin: 0;
    margin-right: 15px;
    padding: 0;
    font-weight: 700;
    font-size: 14px;
    color: rgba(217, 217, 217, 0.76);
}