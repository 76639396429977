.dashboard-page {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  .dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 95%;
    height: 90%;
    background: var(--primary-container-bg-gradient), url("../../../public/backgrounds/faded-grid-bg.svg");
    backdrop-filter: var(--backdrop-blur);
    border: var(--primary-border);
    box-shadow: var(--primary-box-shadow);
    border-radius: 1rem;
    /* Note: backdrop-filter has minimal browser support */
    padding: 20px;
    margin: 20px;

    .dashboard-title {
      color: white;
      font-size: 30px;
      font-weight: bolder;
      margin-bottom: 10px;
      margin: 10px;
    }
    .dashboard-items {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      .item-container {
        background: linear-gradient(
            113deg,
            #53b9ea 1.19%,
            rgba(17, 50, 80, 0.42) 51.57%,
            #113250 97.92%
          );
        // background: linear-gradient(117.25deg, #98ACF1 1.27%, #18369F 102.54%);
        // box-shadow: 2px 2px 2px rgba(214, 214, 214, 0.25);
        padding: 10px;
        margin: 10px;
        border-radius: 13px;
        width: 275px;
        height: 200px;

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .title {
          font-weight: bolder;
          color: white;
        }
        .icon{
            margin-top: auto;
            margin-bottom: auto;
            height:90px;
            width:100px;
        }
      }
    }
  }
}
