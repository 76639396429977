.table-row-title {
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202.5px;
    height: 87px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    background: linear-gradient(125.66deg, rgba(72, 86, 83, 0.4266) 3.23%, rgba(93, 95, 82, 0.54) 55.47%, rgba(68, 93, 87, 0.54) 97.32%);
}

.table-row-item {
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202.5px;
    height: 87px;
    color: white;
    font-weight: 700;
    font-size: 14px;
}

.table-row-title-last {
    border: 1px solid white;
    border-bottom-left-radius: 12.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202.5px;
    height: 87px;
    color: white;
    font-weight: 700;
    font-size: 14px;
    background: linear-gradient(125.66deg, rgba(72, 86, 83, 0.4266) 3.23%, rgba(93, 95, 82, 0.54) 55.47%, rgba(68, 93, 87, 0.54) 97.32%);
}

.table-row-item-last {
    border: 1px solid white;
    border-bottom-right-radius: 12.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202.5px;
    height: 87px;
    color: white;
    font-weight: 700;
    font-size: 14px;
}