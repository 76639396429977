.heading-container-screen {
    display: flex;
    align-items: center;
    margin-bottom: 44px;
}

.screen-heading-title {
    margin: 0;
    padding: 0;
    margin-right: 25px;
    font-size: 32px;
    font-weight: 700;
    color: white;
    text-shadow: 3px 4px 7px rgba(0, 43, 85, 1);
}

/* Table */
.screen-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    border-radius: 12.5px;
    border: 1px solid white;
    background-color: rgba(24, 33, 37, 0.79);
}

.screen-table-tabs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid white;
    height: 55px;
    border-radius: 12.5px 12.5px 0 0;
    background-color: rgba(255, 255, 255, 0.81);
}

.screen-table-tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 184px;
    height: 55px;
    border-right: 1px solid white;
    font-weight: 700;
    font-size: 16px;
    color: rgba(24, 33, 37, 0.89);
}

.screen-table-content-container {
    padding: 81px 116px;
}

.no-data {
    display: flex;
    height: 100%;
    color: white;
    justify-content: center;
    align-items: center;
}