.safety-alert-container {
  padding: 4rem 5rem;
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  background-color: white;

  @media (max-width: 768px) {
    padding: 8px;
    max-width: 600px;
  }
}

.safety-alert-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.safety-alert-header img {
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    max-width: 140px;
  }
}

.safety-alert-border-box {
  border: 1px solid black;
}

.safety-alert-border-box table,
.safety-alert-border-box tbody,
.safety-alert-border-box tr {
  width: 100%;
  border: 1px solid black;
}

.safety-alert-border-box td {
  box-shadow: inset 0 0 0 0.5px black !important;
  color: black !important;
  padding: 10px;
}

.safety-alert-border-box .safety-alert-row-1 {
  width: 20%;
}

@media (max-width: 768px) {
  .safety-alert-border-box .safety-alert-row-1,
  .safety-alert-border-box tbody,
  .safety-alert-border-box tr,
  .safety-alert-border-box td {
    width: 100%;
  }

  .safety-alert-border-box tr {
    display: flex;
    flex-wrap: wrap;
  }
}

.safety-alert-summary,
.safety-alert-actions {
  padding: 1rem;
}

.safety-alert-summary {
  border-bottom: 1px solid black;
}

.safety-alert-summary img,
.safety-alert-actions img {
  width: 100%;
  height: auto;
  padding: 8px 0;
}

.safety-alert-summary h5,
.safety-alert-actions h5 {
  font-size: 1.25rem;
  margin-bottom: 12px;
  font-weight: 600;
}

.safety-alert-summary p,
.safety-alert-actions ol {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.safety-alert-actions ol {
  padding-left: 20px;
}

.safety-alert-actions li {
  margin-bottom: 8px;
}
