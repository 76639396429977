.edit-popup {
    padding: 0;
}

.edit-popup-btn-grp {
    display: flex;
    width: 400px;
    justify-content: space-between;
    align-items: center;
}

.edit-popup-subtext {
    color: rgba(217, 217, 217, 0.76);
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    margin-top: 12px;
    margin-bottom: 38px;
}

.edit-popup-subtext-2 {
    color: white;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    max-width: 80px;
    padding: 10px;
    border: 1px solid white;
    border-radius: 45px;
    cursor: pointer;
}

.headline-popup {
    font-size: 20px;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-icon {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    top: 12px;
    right: 25px;
}

.mcq-screen {
    width: 450px;
    height: 310px;
    margin: 15px auto;
    background-color: #C4C4C4;
    color: black;
    padding: 20px 10px 10px;
    border-radius: 8px;
    overflow-y: auto;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.form .options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.form .options input {
    width: 60vw;
    padding: 0 5px;
    border-radius: 5px;
}

.correct-answer {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
}