.bold-text {
  font-weight: bold;
}
.blur-bg {
  backdrop-filter: blur(3px);
}
.blur-bg-10 {
  backdrop-filter: blur(10px);
}
.border-gray {
  border: 3px solid #c1b1b1 !important;
}
.bold-text {
  font-weight: bold;
}
.blur-bg {
  backdrop-filter: blur(3px);
}
.blur-bg-10 {
  backdrop-filter: blur(10px);
}
.border-gray {
  border: 3px solid rgb(193, 177, 177) !important;
}
.w-90 {
  width: 90%;
}
.cursor-pointer:hover {
  cursor: pointer !important;
}
.cursor-default:hover {
  cursor: default !important;
}
.color-white {
  color: #fff;
}
a.table-link {
  color: #0747ae;
  &:hover {
    color: #0062ff;
  }
}
.unset-all {
  all: unset;
}
