.KuehneNagel {
  color: black;
  background-image: url("../../../../public/backgrounds/cube.png"),
    radial-gradient(169.92% 169.92% at 50% -28.92%, #113250 0%, #062037 100%);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  text-transform: initial;
  .KuehneNagel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
  }

  .KuehneNagel-page {
    // min-height: 100vh;
    width: 70%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px 50px;
    border-radius: 8px;
    margin-bottom: 1rem;

    @media screen and (max-width: 768px) {
      padding: 10px 18px;
      width: auto;
    }
  }

  .KuehneNagel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 5px solid;
    border-image: linear-gradient(
      to right,
      #4a9fd9,
      #d1e6f2
    ); /* Adjust the colors based on the image */
    border-image-slice: 1;
  }

  .KuehneNagel-footer {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    border-top: 5px solid;
    border-image: linear-gradient(
      to right,
      #4a9fd9,
      #d1e6f2
    ); /* Adjust the colors based on the image */
    border-image-slice: 1;

    @media screen and (max-width: 768px) {
      display: block;
    }

    span {
      font-size: 0.8rem;

      @media screen and (max-width: 768px) {
        font-size: 0.7rem;
      }
    }
  }

  .KuehneNagel-textHeader {
    font-size: x-large;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      font-size: medium;
    }
  }

  .KuehneNagel-subheader {
    font-size: large;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      font-size: small;
    }
  }

  .kc-d {
    color: #042b53;
  }
  .kc-l {
    color: #8fabca;
  }
  .k-list {
    li {
      font-size: medium;
    }
  }

  li::marker {
    color: #4a9fd9;
  }
}
