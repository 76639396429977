.userManagement__Display {
  color: var(--text-primary);
}

.createUser__header {
  background: rgb(13, 8, 85);
  background: linear-gradient(
    90deg,
    rgba(13, 8, 85, 1) 0%,
    rgba(53, 76, 193, 1) 50%,
    rgba(13, 8, 85, 1) 100%
  );
  height: 130px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  padding-bottom: 20px;
}

.viewEditUser-btn {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: black;
  border-radius: 4px;
  border: none;
  padding: 7px 0;
  width: 10%;
  font-weight: 900;
}

.createUser__headerImage {
  height: 150px;
  width: 150px;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  // left: 44%;
  top: 55%;
  margin-bottom: 5rem;
  // border: 2px solid brown;
  // border-radius: 50%;
}

.createEditView__bg {
  min-height: 590px;
  flex-grow: 1;
  height: 80%;
  padding-top: 5rem;
}

.documentsTab {
  .documents {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    .documents__Sn {
      font-weight: bold;
    }
    .documents__Name {
      font-size: large;
    }
  }
  .documents:hover {
    background-color: rgb(45, 129, 255);
    font-weight: bolder;
    background: linear-gradient(
      90deg,
      rgba(13, 8, 85, 1) 0%,
      rgba(53, 76, 193, 1) 50%,
      rgba(13, 8, 85, 1) 100%
    );
    .buttons {
      background-color: rgb(255, 255, 255) !important;
      // &:hover {
      //   background-color: #39549e !important;
      // }
      .delete__icon {
        color: red;
      }
      .download__icon {
        color: green;
      }
    }
  }
}

.profilePicture--container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 60%;
  // border:1px solid red;
  // background: #fff;
  backdrop-filter: blur(15px);
  background-image: url("../../images/Ellipse1.png");
  // box-shadow: 0 0 0 1px #e6e6e6 ;
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .upload-btn {
    display: flex;
    color: "white";
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
