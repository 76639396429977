/* App.css */
.induction-learning-body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-image: url('../../assets/background_image.png');
  /* background-size: 100%; */
} 


.main-content {
  display: flex;
  /* flex-wrap: wrap; */
  padding: 24px 100px;
  /* align-items: center; */
}

.link-tag-style {
  text-decoration: none;
  color: inherit;
}