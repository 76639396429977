.container-fluid {
  padding: 1rem;
}

.heading {
  white-space: pre-line !important;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid transparent;
  border-radius: 0px;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card .card-title {
  position: relative;
  font-weight: 600;
}

// button styles default
.buttons {
  background-color: #000058 !important;
  border-color: #000058 !important;
}
.buttons:hover {
  background-color: #39549e !important;
  border-color: #000058 !important;
}
.buttons:focus {
  background-color: #000058 !important;
  border-color: #000058 !important;
}

/*
  Table styles
*/
@import "./table.scss";

/* ************************************************************
  Modal Styles
*/
$modal-content-bg: none;
$modal-content-color: var(--text-primary);
.modal-content {
  background: var(--modal-bg);
  backdrop-filter: var(--backdrop-blur);
}

.modal-header {
  background: var(--primary-container-bg-gradient);
}

/* ************************************************************
Modal Styles Ends
*/

/* ************************************************************
Card Styles
*/
$card-bg: var(--primary-container-bg-gradient);
$card-border-color: var(--primary-border-color);
$card-title-color: var(--text-primary);
$card-subtitle-color: var(--text-primary);
.card {
  backdrop-filter: var(--backdrop-blur);
}

/* ************************************************************
Card Styles Ends
*/

/* ************************************************************
Accordion Styles
*/
$accordion-button-bg: #0c2944;
$accordion-button-active-bg: #2574be;
$accordion-button-active-color: var(--text-primary);
$accordion-color: var(--text-primary);
$accordion-border-color: var(--primary-border-color);
$accordion-border-width: 1px;
.accordion-button {
  font-size: 1.75rem !important;
  // background: var(--primary-container-bg-gradient) !important;
  border: var(--primary-border) !important;
}
.accordion-item {
  box-shadow: var(--primary-box-shadow) !important;
  border: none !important;
}
/* ************************************************************
Accordion Styles Ends
*/

$nav-link-color: white;
$nav-link-hover-color: yellow;