.screen-heading-title {
    margin: 0;
    padding: 0;
    margin-right: 25px;
    font-size: 32px;
    font-weight: 700;
    color: white;
    text-shadow: 3px 4px 7px rgba(0, 43, 85, 1);
}

.search-screen-main-content-table {
    width: fit-content;
    border-radius: 12.5px;
    border: 1px solid rgba(255, 255, 255, 0.81);
}

.search-screen-error {
    padding: 50px;
    color: white;
    font-size: 32px;
    font-weight: 700;
}

.search-screen-main-content-table-container {
    width: 100%;
    max-height: 557px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4a4a4a #272727;
    /* Change these colors as needed */
}

.search-screen-main-content-table-container::-webkit-scrollbar {
    width: 12px;
}

.search-screen-main-content-table-container::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 6px;
}