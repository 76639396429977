.privacy-policy{

    .section{
        margin-bottom: 2rem;
        .main-heading{
            text-align: center;
            font-weight: 800;
            font-size: 4rem;
        }
        .heading{
            color:rgb(2, 37, 55);
            font-size: 2rem;
            font-weight: 700;
        }
        .main-body{
            font-weight: 500;
        }
        .body{
            color:gray;
            padding:0 1rem;
            font-size: larger;
            .sub-body{
                margin-bottom: 1rem;
            }
        }

    }
    
}