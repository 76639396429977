.login-wrapper{
    display:flex;

}
.login-page {
  background: url(../../images/login-bg.jpg) no-repeat center center;
  background-repeat: no-repeat;
  flex:1;
  background-position: center;
  background-size: cover;
  height: 100vh;
  min-height:fit-content;
  min-height: 750px;
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  display: flex;
  background: rgba(255, 250, 250, 0.12);
  box-shadow: inset 44.1667px -44.1667px 44.1667px rgba(165, 165, 165, 0.049),
    inset -44.1667px 44.1667px 44.1667px rgba(255, 255, 255, 0.049);
  backdrop-filter: blur(54.7667px);
  padding: 2em;
  /* Note: backdrop-filter has minimal browser support */
  
  border: 2px solid gray;

  min-width: 120px;
  max-width: 550px;
  min-height: 620px;
  max-height: 750px;
  border-radius: 27px;
  height:80vh;
  
}
.logo-img{
  height:20vh;
  min-height:100px;
}
.login-button {
    background-color: rgba(0, 43, 85, 1);
    box-shadow: 1px 1px 12px rgba(223, 223, 223, 0.25);
border-radius: 11px;
}

