.induction-learning-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 143px;
    height: 125px;
    border: 1px solid white;
    border-radius: 12.5px;
    background-color: rgba(217, 217, 217, 0.76);
    color: rgba(24, 33, 37, 0.89);
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
    cursor: pointer;
}

.induction-learning-card-delete-training{
    position: absolute;
    height: 10px;
    width: 10px;
    top: 5px;
    right: 10px;
    color: red;
    cursor: pointer;
}

.induction-learning-card-img {
    height: 64px;
    width: 64px;
    object-fit: contain;
    object-position: center;
}