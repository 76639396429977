$table-color: white;

// Montserrat [font-family: 'Montserrat', sans-serif;]
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

@import url("https://fonts.googleapis.com/css?family=Calibri:400,300,700");

:root {
  --primary-color: #113250;
  --text-primary: white;
  --primary-border-radius: 6px;
  --backdrop-blur: blur(10px);
  --top-border-radius: 6px 6px 0 0;
  --no-top-border-radius: 0 0 6px 6px;
  --primary-box-shadow: 0px 48px 69px 0px #17122bd9;
  --primary-container-bg-gradient: linear-gradient(
    211deg,
    rgba(17, 50, 80, 0.612) 0.01%,
    rgba(17, 50, 80, 0.702) 42.05%,
    rgba(17, 50, 80, 0) 104.81%
  );
  --primary-border-color: #ffffff40;
  --primary-border: 1px solid var(--primary-border-color);

  /* heights */
  --header-height: 78px;

  /* Modal */
  --modal-bg: linear-gradient(
    211deg,
    #113250cc 0.01%,
    #113250e6 42.05%,
    #113250 104.81%
  );

  text-transform: uppercase;
}

body {
  color: var(--text-primary) !important;
  margin: 0;
  font-family: "Calibri", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100vh;
  background: url("../../public/backgrounds/cube.png"),
    radial-gradient(
    169.92% 169.92% at 50% -28.92%,
    #113250 0%,
    #062037 100%
  );
  background-size: cover; /* Set the image to cover all the div */
  background-repeat: no-repeat; /* Commonly used for that situation */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
    Autocomplete classes
*/
@import "./autocomplete.scss";

/*
    Bootstrap classes overrides
*/
@import "./bootstrap.classes.scss";

// to remove the arrows on input-type == number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
  Utility classes
*/
@import "./utilities.scss";


@import "~bootstrap/scss/bootstrap";

.form-searchable-select {
  color: $form-select-color;
}