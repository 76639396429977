.sidebar {
    padding: 18px 28px;
    margin-right: 32px;
    max-width: 286px;
    border: 1px solid white;
    border-radius: 25px;
    background-color: rgba(24, 33, 37, 0.79);
    box-shadow: 2px 6px 4px 0px rgba(255, 255, 255, 0.56);
}

.scroll-sidebar {
    max-width: 258px;
    max-height: 600px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4a4a4a #272727;
}

.scroll-sidebar::-webkit-scrollbar {
    width: 8px;
}

.scroll-sidebar::-webkit-scrollbar-thumb {
    background-color: #4a4a4a;
    border-radius: 6px;
}

.sidebar-expand {
    font-size: 14px;
    font-weight: 700;
    color: white;
    cursor: pointer;
}

/* Sidebar component */
.sidebar-component {
    display: flex;
    align-items: center;
    background-color: rgba(217, 217, 217, 0.76);
    border: 1px solid white;
    border-radius: 25px;
    padding: 14px;
    max-width: 246px;
    max-height: 73px;
    margin-top: 10px;
    margin-right: 12px;
    margin-bottom: 10px;
}

.sidebar-component-bg-color {
    background-color: #998bb5;
}

.sidebar-component:hover {
    cursor: pointer;
}

.sidebar-name-learning {
    color: black;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    max-width: 139px;
    max-height: 45px;
    overflow: hidden !important;
    line-height: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.sidebar-img {
    margin-right: 34px;
    width: 45px;
    height: 45px;
    object-fit: contain;
    object-position: center;
}

.active-btn {
    width: 197px;
    height: 2px;
    background: linear-gradient(to right, rgba(172, 175, 175, 1), rgba(21, 29, 32, 0), rgba(172, 175, 175, 1));
}

.display-none {
    display: none;
}