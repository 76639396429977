.drivers-dashboard-wrapper {
  .card {
    border: var(--primary-border);
  }
  .border-none {
    border: none !important;
  }
  .chart-container-500 {
    border: var(--primary-border);
    height: 500px;
    position: relative;
  }
  .chart-container-400 {
    border: var(--primary-border);
    height: 400px;
    position: relative;
  }
  .drivers-table {
    margin-bottom: 32px;
    .tableContainer {
      overflow: auto;
    }
  }
  .chart-subtitle {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .chart-select {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 1;
    border: none;
    opacity: 0.7;
  }
  .chart-select-precision {
    position: absolute;
    top: 10%;
    right: 2%;
    z-index: 1;
    border: none;
    opacity: 0.7;
  }
  .chart-noDataFound-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: larger;
  }
  .no-document-btn {
    cursor: not-allowed;
  }
  .details-card-btn {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.2;
    background-color: transparent;
    border: none;
    color: fuchsia;
    &:hover {
      background-color: transparent;
      color: blue;
    }
  }
}
