.react-autosuggest__suggestions-container {
  position: absolute;
}

.autocomplete {
  ul {
    padding: 5px;
    margin-top: 5px;
    background-color: #fff;
    color: black;
    box-shadow: 0 3px 6px rgb(149 157 165 / 15%);
    border: 1px solid rgba(33, 33, 33, 0.07);

    li {
      list-style: none;
      padding: 5px;
      text-align: left;
      border-radius: 0.35rem;
      background-color: rgba(255, 255, 255, 1);

      &:hover {
        cursor: pointer;
        background-color: rgba(255, 122, 122, 0.15);
      }
    }
  }
}