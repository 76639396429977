// $table-cell-padding-y: 2px;
// $table-cell-padding-x: 2px;

$table-border-color: #127ea5;
$table-header-color: #12233e;
// $table-color: #127ea5;
$table-bg: white;

.table > :not(caption) > * > * {
  padding: 0.2rem !important;
}

table {
  border: 1px solid $table-border-color;
  caption-side: bottom;
  border-collapse: collapse;
  table-layout: fixed;
  thead {
    position: sticky;
    top: -1px;
    tr {
      th {
        background-color: $table-header-color !important;
        text-align: -webkit-match-parent;
        white-space: nowrap;
        box-shadow: inset 0 0 0 .5px #000 !important;
      }

      input,
      select,
      .form-control {
        padding: 0.15rem 0.35rem !important;
        font-size: 0.85rem !important;
        border: none;
        max-width: 8rem;
        &:focus {
          outline: none;
          // box-shadow: 0 0 10px #719ece;
        }
      }
    }
  }
  tbody {
    td {
      box-shadow: inset 0 0 0 0.5px #0f7fa7 !important;
      color: $table-border-color !important;
      white-space: nowrap; // Prevent sentence from breaking into next lines
      width: fit-content;
      overflow: hidden;
    }
  }
}

// .table {
//   border: 1px solid $table-border-color;
//   // width: fit-content !important;
//   caption-side: bottom;
//   border-collapse: collapse;

//   > :not(caption) > * > * {
//     box-shadow: inset 0 0 0 0.5px black !important;
//   }

//   tbody {
//     color: $table-border-color;
//     font-weight: 500;
//     font-size: 13px;
//     tr,
//     td {
//       box-shadow: inset 0 0 0 0.5px #0f7fa7;
//       padding: 0.2rem;
//       // white-space: nowrap; // Prevent sentence from breaking into next lines
//       // width: fit-content;
//       // overflow: hidden;
//     }

//     tr {
//       padding: 0;
//     }
//   }

//   thead {
//     position: sticky;
//     top: 0;
//     background-color: $table-header-color;
//     tr {
//       th {
//         padding-left: 0.3rem;
//         padding-bottom: 0.2rem;
//         position: relative;
//         font-weight: bold;
//         text-align: center;
//         overflow: hidden;

//         text-align: -webkit-match-parent;
//         white-space: nowrap;
//         &::first-letter {
//           text-transform: uppercase;
//         }

//         input {
//           font-size: 13px !important;
//           border: none;
//           width: 8rem;
//           &:focus {
//             outline: none;
//             // box-shadow: 0 0 10px #719ece;
//           }
//         }
//       }
//     }
//   }
// }

* {
  outline: none;
}

// .table th,
// .table thead th {
//   font-weight: 500;
// }

// .table thead th {
//   vertical-align: bottom;
// }

// .table th {
//   vertical-align: top;
// }

// .table th,
// .table thead th {
//   font-weight: 500;
// }

// th {
//   text-align: inherit;
// }

.tableContainer {
  max-height: 70vh;
  overflow: auto;
}
// .styled-table {
//     table-layout: fixed;
//     border-collapse: collapse;
//     font-family: sans-serif;
//     min-width: 400px;
//     box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
// }
// .styled-table thead{
//     position: sticky;
//     top: 0px;
// }
// .styled-table thead tr {
//     background: linear-gradient(
//     90deg,
//     rgba(13, 8, 85, 1) 0%,
//     rgba(53, 76, 193, 1) 50%,
//     rgba(13, 8, 85, 1) 100%
//   );
//     color: #ffffff;
//     text-align: left;
//     font-size: small;
// }
// .styled-table th{
//     font-size: larger;
//     text-align: left;
// }
// .styled-table td {
//     text-align: left;
// }
// .styled-table tbody tr {
//     font-size: small;
// }
// .styled-table tbody tr:nth-of-type(even) {
//     background-color: #f3f3f3;
// }
// .styled-table tbody tr:nth-of-type(odd) {
//     background-color: #f6f5ffd4;
// }

// .styled-table tbody tr:last-of-type {
//     border-bottom: 2px solid #009879;
// }
