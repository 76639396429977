.drivers-additional-details-container {
  button.attachment-download-btn {
    color: blue;
    &:hover {
      text-decoration: underline;
    }
    font-size: medium;
    border: none;
  }
  button.attachment-delete-btn {
    svg {
      display: block;
      height: 24px;
      width: 24px;
      color: crimson;
    }
    border: none;
  }
}
