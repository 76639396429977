.feature__display {
  margin: 20px !important;
  border-radius: 10px;
  padding: 1.5rem;
  background: var(--primary-container-bg-gradient);
  box-shadow: var(--primary-box-shadow);
  backdrop-filter: var(--backdrop-blur);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: var(--primary-border-radius);
}

.header__gradient {
  background: linear-gradient(
    92.63deg,
    rgb(16, 25, 70) 2.74%,
    rgb(23, 76, 184) 50.06%,
    rgb(7, 41, 71) 121.76%
  );
}

.tab-header {
  background: linear-gradient(
    92.63deg,
    rgb(16, 25, 70) 2.74%,
    rgb(23, 76, 184) 50.06%,
    rgb(7, 41, 71) 121.76%
  );
  padding: 1rem 1rem 0;

  .nav-item {
    .nav-link {
      color: white !important;
    }

    .active {
      color: #000058 !important;
    }
  }
}

.tab-body {
  padding: 2rem;
}

// .accordion__header {
//   .accordion-button {
//     color: white;
//     font-weight: bolder;
//     // background: #5d7aeb;
//     background: linear-gradient(
//       92.63deg,
//       rgb(160, 176, 255) 2.74%,
//       rgb(217, 230, 255) 50.06%,
//       rgb(160, 176, 255) 121.76%
//     );
//   }
//   .collapsed {
//     color: rgb(30, 30, 30);
//   }
// }
// .accordion__body {
//   background: linear-gradient(
//     92.63deg,
//     rgba(68, 101, 134, 0.427) 2.74%,
//     rgba(91, 132, 173, 0.392) 70.06%,
//     rgba(68, 101, 134, 0.427) 121.76%
//   );
// }
.bg-light-success {
  background-color: rgb(196, 255, 196);
}
.bg-light-danger {
  background-color: rgb(255, 196, 196);
}

.bg-blue-gradient {
  background: linear-gradient(
    92.63deg,
    rgb(112, 128, 210) 2.74%,
    rgb(119, 164, 253) 50.06%,
    rgb(127, 142, 213) 121.76%
  );
  background: linear-gradient(117.25deg, #98acf1 1.27%, #18369f 102.54%);
}
.bg-gray-gradient{
  background: linear-gradient(92.63deg,rgba(49,82,114,.427) 2.74%,rgba(73,106,138,.392) 70.06%,rgba(20,62,102,.406) 121.76%) !important;
}
