.delete-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
}

.delete-popup-heading {
    font-weight: 700;
    font-size: 20px;
    color: white;
    padding-bottom: 0;
    margin-bottom: 0;
}

.delete-popup-text {
    font-weight: 400;
    font-size: 16px;
    color: white;
    margin: 0;
    padding: 0;
    margin-top: 26px;
}

.delete-popup-button-group {
    display: flex;
    height: 50px;
    margin-top: 26px;
}