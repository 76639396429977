.scrollable-container-parent-add-text {
   width: 65vw;
   max-height: 500px;
   height: auto;
   font-size: 16px;
   border: 1px solid white;
   background-color: rgba(196, 196, 196, 1);
   border-radius: 15px;
   padding: 12.5px;
}

.scrollable-container-add-text {
   background-color: rgba(196, 196, 196, 1);
   width: 100%;
   border: none;
   padding-right: 15px;
   max-height: 475px;
   /* max-width: 65vw - 50px; */
   overflow-y: auto;
   scrollbar-width: thin;
   scrollbar-color: #4a4a4a #272727;
   /* Change these colors as needed */
}

.scrollable-container-add-text::-webkit-scrollbar {
   width: 12px;
}

.scrollable-container-add-text::-webkit-scrollbar-thumb {
   background-color: #4a4a4a;
   border-radius: 6px;
}